import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "distributors-display" }
const _hoisted_3 = {
  key: 1,
  class: "distributors-display"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tooltip = _resolveComponent("tooltip")
  const _component_MultiSelect = _resolveComponent("MultiSelect")

  return (!_ctx.dataItem?.inEdit)
    ? (_openBlock(), _createBlock("td", _hoisted_1, [
        (_ctx.plusCount)
          ? (_openBlock(), _createBlock(_component_tooltip, {
              key: 0,
              content: _ctx.tooltipNames,
              position: 'bottom',
              filter: 'span'
            }, {
              default: _withCtx(() => [
                _createVNode("span", _hoisted_2, _toDisplayString(_ctx.displayNames), 1)
              ]),
              _: 1
            }, 8, ["content"]))
          : (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.displayNames), 1))
      ]))
    : (_openBlock(), _createBlock("td", _hoisted_4, [
        _createVNode(_component_MultiSelect, {
          class: "distributors-multiselect",
          "data-items": _ctx.distributorOptions,
          modelValue: _ctx.selectedOptions,
          textField: "name",
          dataItemKey: "code",
          autoClose: false,
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateValue($event)))
        }, null, 8, ["data-items", "modelValue"])
      ]))
}