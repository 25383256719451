
import { defineComponent } from "vue";
// @ts-expect-error
import { loadingSpinner } from "aml";
import { IncentiveProgram } from "@/models";
import { IncentiveProgramsService } from "@/services";
import IncentiveProgramsGrid from "./incentive-programs-grid.vue";
import { OptionVM } from "@/interfaces";

export default defineComponent({
  name: "IncentivePrograms",
  components: {
    loadingSpinner,
    IncentiveProgramsGrid,
  },
  inject: ["api"],
  data() {
    return {
      programs: [] as IncentiveProgram[],
      distributorOptions: [] as OptionVM[],
      areExpiredIncluded: false,
      areProgramsLoading: true,
      areDistributorsLoading: true,
      apiTyped: new IncentiveProgramsService(this.api),
    };
  },
  mounted() {
    this.loadDistributors();
  },
  methods: {
    async loadPrograms(includeExpired: boolean) {
      this.areProgramsLoading = true;
      try {
        const response = await this.apiTyped.getIncentivePrograms(
          includeExpired
        );
        this.programs = response;
      }
      catch(error) {
        this.$errorModal(error);
      } finally {
        this.areProgramsLoading = false;
      }
    },
    async loadDistributors() {
      this.areDistributorsLoading = true;
      try {
        const response = await this.api.getDistributors();
        this.distributorOptions = response;
      }
      catch(error) {
        this.$errorModal(error);
      } finally {
        this.areDistributorsLoading = false;
      }
    },
    async save(item: IncentiveProgram) {
      const itemToSave = new IncentiveProgram();
      itemToSave.deserialize(item); 

      this.areProgramsLoading = true;
      const type = this.getTypeForMessage(item.id);
      try {
        const savedItem = await this.createOrUpdate(itemToSave);
        this.updateItem(item, savedItem);
        this.showSuccessToast(`Incentive Program ${type} succesfully`);
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.areProgramsLoading = false;
      }
    },
    async createOrUpdate(item: IncentiveProgram) {
      return await (item.id
        ? this.apiTyped.updateIncentiveProgram(item)
        : this.apiTyped.createIncentiveProgram(item));
    },
    getTypeForMessage(id: number) {
      return id ? "updated" : "created";
    },
    updateItem(item: IncentiveProgram, savedItem: IncentiveProgram) {
      item.id = savedItem.id;
      item.rowVersion = savedItem.rowVersion;
      item.inEdit = false;
    },
    showSuccessToast(message: string) {
      this.$toast({ message, type: "success" });
    }
  },
  computed: {
    isLoading(): boolean {
      return this.areDistributorsLoading || this.areProgramsLoading;
    },
    distributors(): OptionVM[] {
      return this.distributorOptions;
    }
  },
  provide() {
    // To provide reactive property we need to use a function returning computed property
    return {
      distributors: () => this.distributors
    }
  },
  watch: {
    areExpiredIncluded: {
      handler: function (newValue: boolean) {
        this.loadPrograms(newValue);
      },
      immediate: true,
    },
  },
});
