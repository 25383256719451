
import { defineComponent, PropType } from "vue";
import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-vue-dropdowns";
import { Tooltip } from "@progress/kendo-popups-vue-wrapper";
import { IncentiveProgram } from "@/models";
import { OptionVM } from "@/interfaces";

const MAX_TO_DISPLAY = 5;

export default defineComponent({
  name: "DistributorLinksCell",
  components: {
    MultiSelect,
    Tooltip
  },
  inject: ["api", "distributors"],
  props: {
    dataItem: Object as PropType<IncentiveProgram>,
    field: String
  },
  methods: {
    updateValue(event: MultiSelectChangeEvent) {
      const codes = event?.value?.map((a: OptionVM) => a.code) || [];
      this.$emit("change", { dataItem: this.dataItem, field: this.field, target: { value: codes } });
    }
  },
  computed: {
    distributorOptions(): OptionVM[] {
      // To inject reactive property we need to provide a function returning computed property
      // @ts-expect-error 
      return this.distributors() as OptionVM[] || [];
    },
    selectedOptions(): OptionVM[] {
      return this.distributorOptions.filter(o => this.dataItem?.distributorCodes.includes(o.code || ""));
    },
    selectedCount(): number {
      return this.selectedOptions.length;
    },
    plusCount(): number {
      return this.selectedCount > MAX_TO_DISPLAY ? this.selectedCount - MAX_TO_DISPLAY : 0;
    },
    displayNames(): string {
       const list = this.selectedOptions.slice(0,MAX_TO_DISPLAY).map(a => a.name + "\n\r");
       if(this.plusCount) list.push(`+${this.plusCount} more`);
       return list.join("");
    },
    tooltipNames(): string {
      return `<span class="distributors-tooltip">${this.selectedOptions.slice(MAX_TO_DISPLAY).map(a => a.name).join("\n\r")}</span>`;
    }
  }
});
