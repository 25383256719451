import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggleButton = _resolveComponent("toggleButton")
  const _component_editable_grid = _resolveComponent("editable-grid")

  return (_openBlock(), _createBlock("form", null, [
    _createVNode(_component_editable_grid, {
      isReadOnly: !_ctx.userAuth.canManageIncentivePrograms,
      items: _ctx.programs,
      columns: _ctx.columns,
      validator: _ctx.validator,
      addNew: _ctx.addNew,
      onSave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('save', $event)))
    }, {
      "toolbar-button": _withCtx(() => [
        _createVNode(_component_toggleButton, {
          modelValue: _ctx.areExpiredIncluded,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:areExpiredIncluded', $event))),
          classAlways: "is-light",
          iconOff: "far fa-square",
          iconOn: "fas fa-check-square",
          titleOn: "Show expired",
          titleOff: "Show expired"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["isReadOnly", "items", "columns", "validator", "addNew"])
  ]))
}