
import { defineComponent, PropType } from "vue";
import { EditableGrid } from "@/components";
// @ts-expect-error 
import { toggleButton, renderCellWithCustomComponent } from "hestia";
import { GridColumnProps } from "@progress/kendo-vue-grid";
import { IncentiveProgram } from "@/models";
import DistributorLinksCell from "./distributor-links-cell.vue";

export default defineComponent({
  name: "IncentiveProgramsGrid",
  inheritAttrs: false,
  components: {
    EditableGrid,
    toggleButton
  },
  emits: ["save", "update:areExpiredIncluded"],
  props: {
    areExpiredIncluded: Boolean,
    programs: {
      type: Array as PropType<IncentiveProgram[]>,
      default: [],
    },
  },
  inject: ["api", "userAuth"],
  data() {
    return {
      columns: [
        {
          field: "incentiveProgramName",
          title: "Name",
          editable: true,
          editor: "text",
          filter: "text",
        },
        {
          field: "effectiveFrom",
          title: "Effective From",
          editable: true,
          editor: "date",
          filter: "date",
          format: "{0:d-MMM-y}",
        },
        {
          field: "effectiveTo",
          title: "Effective To",
          editable: true,
          editor: "date",
          filter: "date",
          format: "{0:d-MMM-y}",
        },
        {
          field: "distributorCodes",
          title: "Distributors",
          cell: renderCellWithCustomComponent(DistributorLinksCell)
        },
        {
          field: "incentiveProgramDescription",
          title: "Description",
          editable: true,
          editor: "text",
          filter: "text",
        },
        { cell: "editItemTemplate", 
          title: "Actions", 
          filterable: false, 
          hidden: !this.userAuth.canManageIncentivePrograms 
        },
      ] as GridColumnProps[],
    };
  },
  methods: {
    addNew() {
      return new IncentiveProgram();
    },
    validator(item: IncentiveProgram, originalItem: IncentiveProgram): boolean | string {
      // Gets the start of the day
      const now = new Date();
      // Make sure dates are start of the day
      const effectiveFrom = item.effectiveFrom;
      const effectiveTo =  item.effectiveTo;
      const originalEffectiveFrom = originalItem.effectiveFrom;
      // Validation rules
      const isToDateBeforeFromDate = effectiveFrom > effectiveTo && "'Effective To' date must be after 'Effective From' date.";
      const isToDateLaterThanToday = effectiveTo < now && "'Effective To' date must be in the future.";
      const isFromDateInPastForNewProgram = item.id === 0 && effectiveFrom < now && "'Effective From' date for a new program must be in the future.";
      const hasFromDateChangedToPast = (effectiveFrom.valueOf() !== originalEffectiveFrom.valueOf() && effectiveFrom < now) && "'Effective From' date cannot be changed to past date.";
      const isNameMissing = item.incentiveProgramName?.trim().length < 1 && "Program name is required.";
      const isNameInvalidLength = item.incentiveProgramName.length > 250 && "Name length must be less than 250 characters.";
      const isDescriptionMissing = item.incentiveProgramDescription?.trim().length < 1 && "Program description is required.";
      const isDescriptionInvalidLength = item.incentiveProgramDescription.length > 500 && "Description length must be less than 500 characters.";
      
      return isToDateBeforeFromDate || isToDateLaterThanToday || isFromDateInPastForNewProgram || hasFromDateChangedToPast || isNameMissing || isNameInvalidLength || isDescriptionMissing || isDescriptionInvalidLength; 
    },
  },
  computed: {
    itemInEdit(): IncentiveProgram | undefined {
      return this.programs.find(a => a.inEdit);
    },
    effectiveFromColumn(): GridColumnProps | undefined {
      return this.columns.find(col => col.field === "effectiveFrom");
    }
  },
  watch: {
    itemInEdit(newItemInEdit: IncentiveProgram | undefined) {
      if(!newItemInEdit || !this.effectiveFromColumn) return;
      this.effectiveFromColumn.editable =  new Date(new Date(newItemInEdit.effectiveFrom).toDateString()) >= new Date(new Date().toDateString());
    }
  }
});
